import React from 'react'
//import { Link } from "gatsby"
import globalStyle from '../css_modules/global.module.scss'
import homeNewsletter from '../css_modules/homenewsletter.module.scss'


const HomeNewsletterSection = () => {
    return (
        <section className={homeNewsletter.homeNewsletterSection}>
            <div className={homeNewsletter.mainSection}>
                <div className={globalStyle.container}>
                    <h2 className={globalStyle.heroTitle}>subscribe to our<br /><label>newsletter</label>
                    </h2>
                    {/* Begin Mailchimp Signup Form */}
                    <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc_embed_signup{clear:left; font:18px; width:100%; }\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: auto;margin-right: 10px;}\n\t#mergeRow-gdpr {margin-top: 20px;}\n\t#mergeRow-gdpr fieldset label {font-weight: normal;}\n\t#mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;border-radius:0; outline: none;}\n" }} />
                    <div id="mc_embed_signup">
                        <form action="https://rocks.us19.list-manage.com/subscribe/post?u=c862da19fe3153189b46c8866&id=828ddc94d9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                            <div id="mc_embed_signup_scroll">
                                {/* <input type="email" defaultValue name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required /> */}
                                <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Email address" required />
                                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_c862da19fe3153189b46c8866_828ddc94d9" tabIndex={-1} defaultValue /></div>
                                <div className="clear"><input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={globalStyle.mainButton} /></div>
                            </div>
                        </form>
                    </div>
                    {/*End mc_embed_signup*/}
                </div>
            </div>

        </section >
    )
}

export default HomeNewsletterSection