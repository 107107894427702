import React from 'react'
// import homeServices from '../css_modules/homeservices.module.scss'
// import homeAbout from '../css_modules/homeabout.module.scss'
import globalStyle from '../css_modules/global.module.scss'
import homeClients from '../css_modules/homeclients.module.scss'
import HomeJurneySection from './homejurney';


const HomeClientsSection = () => {
    return (
        <section className={homeClients.homeClientsSection + ' ' + globalStyle.darkGrayBackground}>
            {/* <div className={homeAbout.sectionLeft}>
                <div className={homeAbout.imgHolder}></div>
            </div> */}
            <div className={homeClients.mainSection}>
                <div className={globalStyle.container800}>
                    <h2 className={globalStyle.heroTitle}>meet our<br /><label>clients and partners</label>
                    </h2>
                </div>
                <div className={globalStyle.container}>
                    <div>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>
            <HomeJurneySection />
        </section >
    )
}

export default HomeClientsSection