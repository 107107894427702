import React from 'react';
import Layout from '../components/layout'
import SEO from '../components/seo'
import PortfolioHeroSection from '../components/portfolio/portfoliohero'
import PortfolioMainSection from '../components/portfolio/portfoliomain';
import HomeClientsSection from '../components/homepage/homeclients'
import HomeNewsletterSection from '../components/homepage/homenewsletter'

const PortfolioPage = () => {
    return (
        <Layout>
            <SEO title="Portfolio | Digital Community building &amp; nurturing - Digital Madness<" />
            {/* <PortfolioHeroSection /> */}
            <PortfolioMainSection />
            <HomeClientsSection />
            <HomeNewsletterSection />
        </Layout>
    )
}

export default PortfolioPage