import React from 'react'
import homeHero from '../css_modules/homehero.module.scss'
import globalStyle from '../css_modules/global.module.scss'
import portfolioMain from '../css_modules/portfolio/portfoliomain.module.scss'
import portfolioHero from '../css_modules/portfolio/portfoliohero.module.scss'
import Link from 'gatsby'
// import ReactDOM from 'react-dom'
// import ReactModal from 'react-modal'

// Modal.setAppElement('#main');

// ReactModal.setAppElement('#testing')


class PortfolioMainSection extends React.Component {
    // constructor() {
    //     super();

    //     this.state = {
    //         showModal: false
    //     };
    //     this.handleOpenModal = this.handleOpenModal.bind(this);
    //     this.handleCloseModal = this.handleCloseModal.bind(this);
    // }
    // handleOpenModal() {
    //     this.setState({ showModal: true });
    // }

    // handleCloseModal() {
    //     this.setState({ showModal: false });
    // }
    // const PortfolioMainSection = () => {
    render() {
        return (
            <section className={portfolioMain.fullSection} >
                <div className={portfolioHero.heroSectionContent}>
                    <div className={globalStyle.container800}>
                        <center><h1 className={globalStyle.h1Hero}>Project showcases</h1></center>
                        <center><p>We support clients across many different industries and locations. See how other businesses are using DigitalMadness to help transform their digital story.</p></center>
                    </div>
                </div>
                <div className={globalStyle.container}>
                    <div className={portfolioMain.section}>
                        <div className={portfolioMain.contentHolderOuter}>
                            <div className={portfolioMain.titleWrapper}>
                                <h3 className={globalStyle.heroTitle}>Building and enabling digital financial tools to drive lead generation and new prospects and clients</h3>
                            </div>
                            <div className={portfolioMain.contentWrapper}>
                                <div className={portfolioMain.contentHolder}>
                                    <p>Digitisation, digital advisory and digital sales and process drive major transformations of this businesses value chains. Several competitors were already embarking on a digitisation journey to offer digital services to their respective clients. The aim was to get ahead of the competition and meet the needs of their clients by implementing a content marketing strategy and get onboard with a digital transformation. High premium clients demanded alternative ways to be targeted due to different needs with investing and being harder to reach through online platforms.</p>
                                    <br />
                                </div>
                                <div className={portfolioMain.imgHolder1}></div>
                            </div>
                            <div className={portfolioMain.ex1}>
                                <label for="item-1" className={portfolioMain.readMoreLink}>Read More</label>
                                <input type="checkbox" name="one" id="item-1" />
                                <div className={portfolioMain.hide}>
                                    <center><strong>The Context:</strong></center>
                                    <p>Our client is a financial institution, trying to target a more modern day customer. In this new digital era, customers are becoming more digitally convenient and want holistic consulting and service (end to end) across all channels. With high competition from other young, digital players such as fintechs or other digital services, the client’s goal  was to stay ahead of the game to reach new and prospecting customers.</p>
                                    <center><strong>Solution</strong></center>
                                    <p>Our solution was to first analyse the current situation, establishing aims and benchmark goals that needed to be reached in the trial period. We then proceeded with a digital footprint to build reach and traffic, and established accounts for ads. We optimised the current website making it visible and reachable for targeted traffic and prospects and improved the digital footprint through new targeted content. Incoming traffic was then monitored and managed and we helped to market <strong>digital asset tool</strong> available to clients and prospects. Using inbound marketing we guided the tool pre launch, during and after the launch phase.</p>
                                    <center><strong>Results</strong></center>
                                    <p>
                                        <ul>
                                            <li>Increased traffic by over 1K</li>
                                            <li>Users -  up 35.61%</li>
                                            <li>New Users up 47.70%</li>
                                            <li>Interaction up 29.05%</li>
                                            <li>Build a proper functioning funnel</li>
                                            <li>Created needed Interfaces to CRM</li>
                                            <li>Created needed processes in the organisation and the teams</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={globalStyle.container}>
                    <div className={portfolioMain.section}>
                        <div className={portfolioMain.contentHolderOuter}>
                            <div className={portfolioMain.titleWrapper}>
                                <h3 className={globalStyle.heroTitle}>Building engagement and Social Media Strategy</h3>
                            </div>
                            <div className={portfolioMain.contentWrapper}>
                                <div className={portfolioMain.contentHolder}>
                                    <p>Our client wanted to build a successful working sales funnel and brand strategy within the different channels. Therefore, it was crucial that all marketers and product experts know the guidelines and understand the changes in managing and posting on social media. Our aim was to implement a social media strategy that builds engagement on a B2B level, and to enable all marketers across the business units to use these strategies successfully.</p>
                                    <br />
                                </div>
                                <div className={portfolioMain.imgHolder2}></div>
                            </div>
                            <div className={portfolioMain.ex2}>
                                <label for="item-2" className={portfolioMain.readMoreLink}>Read More</label>
                                <input type="checkbox" name="two" id="item-2" />
                                <div className={portfolioMain.hide2}>
                                    <center><strong>The Context: </strong></center>
                                    <p>Our client creates the next generation of mobile machines by using electrified, electronic and digitally networked solutions.</p>
                                    <center><strong>Solution</strong></center>
                                    <p>We built a social media strategy, beginning with defined customer persons and defining the customer journey for each group. Following this, was a content placement strategy with target audience, type of content etc for all social media channels. (Facebook, Twitter, LinkedIn, Instagram, YouTube). Strategy framework and guidelines for google ad words, ad manager (LinkedIn and Facebook) were also implemented. A ready-to-go guideline was created for all requested social media channels, showing explicit examples on what the clients typical strategy should look like. We also enabled the sales team, to become part of the social selling strategy.</p>
                                    <center><strong>Results</strong></center>
                                    <p>
                                        <ul>
                                            <li>20 Business Units and their marketers enabled</li>
                                            <li>Increase in engagement on all channels</li>
                                            <li>300 sales representatives trained on social selling</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={globalStyle.container}>
                    <div className={portfolioMain.section}>
                        <div className={portfolioMain.contentHolderOuter}>
                            <div className={portfolioMain.titleWrapper}>
                                <h3 className={globalStyle.heroTitle}>Product Marketing B2B & Scaling / Roll-out</h3>
                            </div>
                            <div className={portfolioMain.contentWrapper}>
                                <div className={portfolioMain.contentHolder}>
                                    <ul>
                                        <li>Increased lead number by 30% through multiple touchpoints and more accurate</li>
                                        <li>Content distribution in targeted segments</li>
                                        <li>Content production increased threefold</li>
                                        <li>Format variety fivefold (scaling by reuse)</li>
                                    </ul>
                                    <br />
                                </div>
                                <div className={portfolioMain.imgHolder3}></div>
                            </div>
                            <div className={portfolioMain.ex3}>
                                <label for="item-3" className={portfolioMain.readMoreLink}>Read More</label>
                                <input type="checkbox" name="three" id="item-3" />
                                <div className={portfolioMain.hide3}>
                                    <center><strong>Problem: </strong></center>
                                    <p>The client needs to set up the digital product "ProBusiness" and to scale in different industries, lead generation and build a lead nurturing route for marketing.</p>
                                    <center><strong>Solution</strong></center>
                                    <p>Our solution was:</p>
                                    <ul>
                                        <li>Design and preparation of a kick-off workshop about strategy and measures</li>
                                        <li>To embed the new strategy in the overall team and above all the sales enablement.</li>
                                        <li>Development of a self-contained lead nurturing route over the whole marketing and sales funnel additions to prequalify and qualify for leads segment</li>
                                        <li>Develop a content strategy for the individual segments, industries and  target groups</li>
                                        <li>Conception and development of sales tools through a gamification approach</li>
                                        <li>Development of concrete measures for successful outbound sales campaigns for small selections</li>
                                        <li>Creation and conception of supporting online marketing campaigns generate seven to ten touchpoints in advance</li>
                                        <li>Development of a customer success approach</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* TESTING  */}

            </section >
        )
        // }
    }
}
// const props = {};
export default PortfolioMainSection
// ReactDOM.render(<PortfolioMainSection {...props} />, document.getElementById('testing'))
