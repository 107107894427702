import React from 'react'
import { Link } from "gatsby"
import globalStyle from '../css_modules/global.module.scss'
import homeJurney from '../css_modules/homejurney.module.scss'


const HomeJurneySection = () => {
    return (
        <section className={homeJurney.homeJurneySection}>
            <div className={homeJurney.mainSection}>
                <div className={homeJurney.jurneyWrapper}>
                    <div className={globalStyle.container + ' ' + globalStyle.grayBackground}>
                        <h2 className={globalStyle.heroTitle}>find out what is best for<br /><label>your business</label>
                        </h2>
                        <p style={{ paddingBottom: '0' }}>Talk to us to find out how we can impact your products, services or organisation through our methods, services and teams.
                            </p>
                        <Link to="contact" className={globalStyle.mainButton}>Get in touch</Link>
                        {/*<div className={homeJurney.selectOptions}>
                             <select name="niche">
                                <option value="">What is your niche</option>
                                <option value="n1">niche 1</option>
                                <option value="n2">niche 2</option>
                                <option value="n3">niche 3</option>
                            </select>
                            <select name="competition">
                                <option value="">What is your competition</option>
                                <option value="c1">competition 1</option>
                                <option value="c2">competition 2</option>
                                <option value="c3">competition 3</option>
                            </select>
                            <select name="budget">
                                <option value="">Your marketing budget</option>
                                <option value="c1">marketing budget 1</option>
                                <option value="c2">marketing budget 2</option>
                                <option value="c3">this is an extra long sentence that won't fit in the container!</option>
                            </select> 
                        </div>*/}
                    </div>
                </div>
            </div>

        </section >
    )
}

export default HomeJurneySection